/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
		init: function() {
			  $(function(){
				  $('.dropdown-menu input').click(function(event){
					  event.stopPropagation();
				  });
			  });

			if (Modernizr.touch) {
				$('ul.nav li.dropdown a').click(function (e){
					e.preventDefault();
				});


				var dropdowns = Array.prototype.slice.call(document.querySelectorAll('ul.nav li.dropdown'));

				var dropdownShown = false;

				var closeAllDropdowns = function() {
					for (var i=0; i<dropdowns.length; i++) {
						var dropdown = dropdowns[i].querySelector('ul.dropdown-menu');
						if (dropdown !== null) {
							dropdown.style.display = 'none';
						}
					}
				};

				var toggleDropdown = function(ev) {
					closeAllDropdowns();
					var source = ev.target || ev.srcElement;
					if (source.tagName === 'A' || source.tagName === 'SPAN') {
						var dropdown = source.parentNode.querySelector('ul.dropdown-menu');
						if (dropdown === null)
							return loadPage(ev);
						ev.preventDefault();
						if (dropdownShown === dropdown) {
							dropdownShown = null;
						} else {
							dropdown.style.display = 'block';
							dropdownShown = dropdown;
						}
					}
				};

				var loadPage = function(ev) {
					var source = ev.target || ev.srcElement;
					if (source.tagName === 'A') {
						window.location = source.href;
					}
				};

				var setupHammers = function() {
					for (var i=0; i<dropdowns.length; i++) {
						dropdowns[i].addEventListener('click', toggleDropdown, false);
						var hammer = new Hammer(dropdowns[i]);
						hammer.on('doubletap', loadPage);
					}
				};
				setupHammers();
			}
			// JavaScript to be fired on all pages
		},
		finalize: function() {
			var carousel = document.querySelector('div.carousel');
			if (carousel !== null) {
				var $carousel = $(carousel);
				var hideWraps = function($element){
					$element.find('a.carousel-control').show();
					if ($element.find('ol.carousel-indicators li').first().hasClass('active')) {
						$element.find('a.carousel-control.left').hide();
					}
					if ($element.find('ol.carousel-indicators li').last().hasClass('active')) {
						$element.find('a.carousel-control.right').hide();
					}
				};
				hideWraps($carousel);
				var carouselInner = carousel.querySelector('.carousel-inner');
				var rightControl = carousel.querySelector('a.carousel-control.right'); 
				var leftControl = carousel.querySelector('a.carousel-control.left'); 
				var indicators = carousel.querySelector('ol.carousel-indicators');
				leftControl.style.top = '0px';
				rightControl.style.top = '0px';
				// JavaScript to be fired on all pages, after page specific JS is fired
				var positionControls = function (){
					
					var currentImage = carouselInner.querySelector('.listing-item.active img');
					var height = currentImage.height;
					var width = currentImage.width;
					var indicTop = height - 30;
					
					indicators.style.top = ''+indicTop+'px';
					
					leftControl.style.left = ((carousel.clientWidth/2 - currentImage.width/2) - leftControl.clientWidth) + 'px';
					rightControl.style.left = ((carousel.clientWidth/2 - currentImage.width/2) + width) + 'px';
					
					leftControl.style.height = height + 'px';
					rightControl.style.height = height + 'px';

					var currentCaption = carouselInner.querySelector('div.listing-item.active div.carousel-caption');
					currentCaption.style.width = width + 'px';
				};
				positionControls();
				$carousel.on('slide.bs.carousel', function() {
					$('div.carousel a.carousel-control').hide();
				});
				$carousel.on('slid.bs.carousel', function() {
					$('div.carousel a.carousel-control').show();
					hideWraps($(this));
					positionControls();
				});
				$(window).scroll(positionControls);

				window.setInterval(positionControls, 300);

				var carouselHammer = new Hammer(carousel);

				carouselHammer.on('swiperight', function() {
					if (!($carousel.find('ol.carousel-indicators li').first().hasClass('active'))) {
						$carousel.carousel('prev');
					}
				});
				carouselHammer.on('swipeleft', function() {
					if (!($carousel.find('ol.carousel-indicators li').last().hasClass('active'))) {
						$carousel.carousel('next');
					}
				});
			}

			var hrPosition = function ($main, $content) {
				$content.find('hr').width($main.width()).css('margin-left', ($content.width() - $main.width())/2);
			};
			var allHrPositions = function() {
				$('div.page-content-wrapper, article.post, div.news')
					.each(function() {
						$main = $(this);
						$main.find('div.page-content, div.entry-content').each(function(){
							$content = $(this);
							hrPosition($main, $content);
						});
					});
			};
			allHrPositions();
			$(window).resize(allHrPositions);

			$('div.page-content-wrapper, article.post, div.news').each(function(){
				$(this).find('a > img').each(function(){
					var $img = $(this);
					var $backlink = $(this).parent();
					$backlink.css('display','inline-block');
					$backlink.css('overflow','hidden');
					$backlink.css('position','relative');
					$('<div />', {
						"class": "hover",
						css: {
							height: $img.height(),
							width: $img.width(),
							position: 'absolute',
							top: 0,
							left: 0,
							backgroundColor: 'black',
							opacity: 0.3,
							display: 'none'
						}
					})
						.insertAfter($img);
					$backlink.hover(function (){
						$backlink.find('.hover').css('display', 'block');
					}, function (){
						$backlink.find('.hover').css('display', 'none');
					});
				});
			});

			var STRIPE_TEST_MODE=true;

			var notifySuccess = function(msg) {
				notif({
				  msg: "<b>Success:</b>" + msg,
				  type: "success"
				});
			};

			var notifyError = function(msg) {
				notif({
				  msg: "<b>Error:</b>" + msg,
				  type: "error"
				});
			};

			var tokenCallback = function(amount) {
				return function(token) {
					jQuery.ajax({
						type: "POST",
						url: stripe_callback_url,
						data: { action: "hg-stripe-post", token: token.id, mode: 'test', amount: amount },
						success: function( msg )
						{
							notifySuccess(msg);
						},
						error: function(xhr, status, error)
						{
							notifyError(xhr.responseText);
						}
					});
				};
			};

			var setupStripeDonate = function(selector) {
				var $form = $(selector);
				var amount = $form.find('input[name="amount"]').val() * 100;
				$form.find('button[type="submit"]').on('click', function(e){

					var config = {
						key: STRIPE_TEST_MODE ? window.stripe_pub_key_test : window.stripe_pub_key_live,
						image: '/app/themes/lebensretter/dist/images/Lebensretter.png',
						locale: 'auto',
					};

					config.token = tokenCallback(amount);

					var handler = StripeCheckout.configure(config);

					handler.open({
						name: 'Lebensretter',
						description: 'Donation',
						currency: "eur",
						amount: amount
					});
					e.preventDefault();
				});
			};
			setupStripeDonate('#nav-donate');
		}
    },
    // Home page
    'front_page': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
